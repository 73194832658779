<script>
import TableauElevesResults from '@/components/enfants/tableauElevesResults.vue'
import TableauPresentation from '@/components/tableauxBlancs/tableauPresentation.vue'
import GroupListSelectionPreview from '@/components/groupes/GroupListSelectionPreview'
import SelectElevesV2 from '@/components/dialogs/actions/selectElevesV2'
import EditeurTableauV2 from '@/mixins/editeurTableauV2.js'
import eleveSelectionParent from '@/mixins/eleveSelectionParent.js'
import LoadListeLecture from '@/mixins/loadListeLecture'
import openLink from '@/mixins/openLink.js'

export default {
  name: 'Tableaux',
  mixins: [LoadListeLecture, EditeurTableauV2, eleveSelectionParent, openLink],
  components: {
    TableauElevesResults,
    TableauPresentation,
    GroupListSelectionPreview,
    SelectElevesV2
  },
  data () {
    return {
      tableau: null,
      loading: true,
      panel: 0,
      eleves: [],
      elevesGroupedByGroup: [],
      showSelectEleves: false,
      identifiant: null
    }
  },
  computed: {
    calendriers () {
      const temp = []
      if (this.tableau && this.tableau.resultat) {
        this.tableau.resultat.forEach((devoir) => {
          if (devoir.titre !== 'Devoirs' && !temp.find(e => e.id === devoir.calendrier.id)) {
            temp.push(devoir.calendrier)
          }
        })
      }
      return temp
    },
    tableauBlancToEdit: {
      get () { return this.$store.state.Dialogs.tableauBlancToEdit },
      set (val) { this.$store.commit('Dialogs/setTableauBlancToEdit', val) }
    },
    showTableauBlancSuppression: {
      get () { return this.$store.state.Dialogs.showTableauBlancSuppression },
      set (val) { this.$store.commit('Dialogs/setShowTableauBlancSuppression', val) }
    },
    showTableauBlancEdition: {
      get () { return this.$store.state.Dialogs.showTableauBlancEdition },
      set (val) { this.$store.commit('Dialogs/setShowTableauBlancEdition', val) }
    },
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    contenuTableau () {
      let temp = []
      if (this.tableau && this.tableau.resultat && this.tableau.resultat[0] && this.tableau.resultat[0].contenu && this.tableau.resultat[0].contenu.tableauxBlancs.length > 0) {
        return this.tableau.resultat[0].contenu.tableauxBlancs[0]
      }
      return temp
    },
    allContenus () {
      return [this.tableau?.resultat?.[0]?.contenu]
    },
    nuage () {
      return this.contenuTableau?.nuageItem
    },
    titreAndDescription () {
      return {
        titre: this.allContenus?.[0]?.titre,
        description: this.allContenus?.[0]?.description
      }
    },
    firstPageBgcStyle () {
      const properties = JSON.parse(this.contenuTableau.data)[0]?.gridProperties
      return !properties ? {} : {
        background: properties.tableauBgColor
      }
    },
    elevesCount () {
      let temp = 0
      if (this.tableau && this.tableau.total > 0) {
        this.tableau.resultat.forEach(element => {
          if (element.calendrier.titre !== 'Devoirs') {
            temp += element.elementsUsagers.total
          }
        })
      }
      return temp
    }
  },
  watch: {
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.description && (newVal.description.includes('Le titre du tableau blanc a bien été modifié.') || newVal.description.includes('Le tableau blanc a été partagé aux élèves sélectionnés'))) {
          this.$apollo.queries.tableau.refetch()
        }
      }
    }
  },
  apollo: {
    tableau: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElementsDetailTableau.gql'),
      variables () {
        return {
          filtre: {
            identifiants: [this.$route.params.id]
          }
        }
      },
      fetchPolicy: 'network-only',
      update (data) {
        this.loading = false
        this.$store.commit('Dialogs/setBackupData', data.calendrierCalendrierElements.resultat)

        const { studentList, groupedBy } = this.cceListToSelectedStudentList(data.calendrierCalendrierElements.resultat)
        this.eleves = studentList
        this.elevesGroupedByGroup = groupedBy

        return data.calendrierCalendrierElements
      }
    }
  },
  mounted () {
    this.loadAllInfoForLectureId(this.$route?.params?.id, false)
    this.loading = true
  },
  beforeDestroy () {
    this.$store.commit('Dialogs/setSelectedEleves', [])
  },
  async created () {
    this.identifiant = this.$route?.params?.id
    this.initialiseSallesUsagersFromIdentifiant(this.identifiant)
  },
  methods: {
    async onEleveChange (usagers) {
      this.onSelectSallesUsager(usagers)
      this.distribuerUsagersToCalendrierElement(this.identifiant)
      const created = await this.distribuerUsagersToCalendrierElement(this.identifiant)
      if (!created) { return this.onCreationFailure() }
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: 'Succès', description: 'Le tableau blanc a été partagé aux élèves sélectionnés.' })
      this.$store.commit('App/setShowSnackbarGlobal', true)
    },
    showEleves () {
      const tempEleves = []
      this.tableau.resultat.forEach(element => {
        if (element.calendrier.titre !== 'Devoirs') {
          // temp += element.elementsUsagers.total
          element.elementsUsagers.resultat.forEach((usager) => {
            tempEleves.push({
              id: usager.usager.id, name: usager.usager, type: 'eleve', calendrier: element.calendrier, classe: element.calendrier.proprietaire
            })
          })
        }
      })
      this.$store.commit('Dialogs/setSelectedEleves', tempEleves)
      this.showElevesSelection = !this.showElevesSelection
    },
    editTableauTitre (tableau) {
      this.tableauBlancToEdit = {
        id: this.tableau.resultat.map(e => e.id),
        titre: this.tableau.resultat[0].titre ? this.tableau.resultat[0].titre : 'Groupe sans nom',
        description: this.tableau.resultat[0].description ? this.tableau.resultat[0].description : null,
        dossier: this.tableau.resultat[0].contenu.id,
        tableau: this.tableau.resultat[0].contenu.tableauxBlancs[0],
        identifiant: this.tableau.resultat[0].identifiant
      }
      this.showTableauBlancEdition = true
    },
    deleteTableau () {
      this.tableauBlancToEdit = {
        id: this.$route.params.id,
        titre: this.tableau.resultat[0].titre ? this.tableau.resultat[0].titre : 'Groupe sans nom',
        description: this.tableau.resultat[0].description ? this.tableau.resultat[0].description : null,
        dossier: this.tableau.resultat[0].contenu?.id,
        tableau: this.tableau.resultat[0].contenu.tableauxBlancs[0]?.id
      }
      this.showTableauBlancSuppression = true
    },
    shareTableau () {
      this.showElevesSelection = true
    },
    goToTableau (page) {
      let query = '?tableau=' + this.tableau.resultat[0].contenu.tableauxBlancs[0].id
      if (page) {
        query += '&page=' + page
      }
      this.writeTokenInCookiesAndOpenLink('https://tableaublanc.dokoma.com/' + query)
    },
    cceListToSelectedStudentList (cceList) {
      // This can be replaced with the mixin 'eleveSelectionParent'
      let studentList = []
      const groupsToDisplay = []

      cceList
        .filter(cce => cce?.calendrier?.proprietaire?.__typename === 'SalleClasseSalle')
        .forEach(cce => {
          const salleId = cce?.calendrier?.proprietaire?.id
          const salleTitre = cce?.calendrier?.proprietaire?.titre

          const studentsToAdd = cce.elementsUsagers.resultat.map(u => ({
            id: u.id,
            usagerId: u?.usager?.id,
            titre: u?.usager?.info?.prenom + ' ' + u?.usager?.info?.nom,
            avatar: u?.usager?.avatar?.thumbnail,
            initial: u?.usager?.info?.prenom?.[0] + u?.usager?.info?.nom?.[0],
            salle: salleId
          }))
          studentList = [...studentList, ...studentsToAdd]

          if (!groupsToDisplay[salleId]) {
            groupsToDisplay[salleId] = {
              id: salleId,
              titre: salleTitre,
              selectedChildren: [],
              locked: false
            }
          }
        })

      studentList.forEach(student => {
        groupsToDisplay[student.salle].selectedChildren.push(student)
      })

      const groupedBy = Object.values(groupsToDisplay).filter(g => g.selectedChildren?.length)

      return { studentList, groupedBy }
    }
  }
}
</script>

<template lang='pug'>
  .devoirs-index.split-page
    select-eleves-v2(
      v-if="showSelectEleves"
      :initialSelectedUsagers="selectedSallesUsager"
      @close="showSelectEleves = false"
      @select="onEleveChange"
      @display="onDisplaySallesUsager"
    )
    template(v-if="nuage")
      tableau-presentation(:nuage="nuage" :contenuProps="allContenus" :titreAndDescription="titreAndDescription")
    .page-header(v-else color='primary')
      .header__inner-content
        v-btn.white--text.mb3(:small='$store.state.App.mobileTemplate', :to='{ name: "tableau" }', rounded, color='primary')
          font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
          span Tous les tableaux
        div.flex-grow-1
        .header-title()
          div.secondary-title Tableau blanc
          template(v-if='tableau && tableau.resultat')
            div.main-title() {{tableau.resultat[0].titre}}
            .header-description {{tableau.resultat[0].description}}
    .page-actions
      v-card(color='primary', @click='goToTableau(false)', v-ripple)
        v-card-title Voir le tableau
      v-card(color='primary', @click='editTableauTitre()', v-ripple)
        v-card-title Modifier le titre du tableau
      v-card(color='primary', @click='deleteTableau()', v-ripple)
        v-card-title Supprimer le tableau blanc
      v-card(color='primary', @click='showSelectEleves = true', v-ripple)
        v-card-title Partager le tableau
    div.page__main-content
      section.small-section.devoir-content
        .section-subtitle__header
          h2.section-subtitle Contenu du tableau
        v-sheet.section-card.page-section(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          v-skeleton-loader(v-if='loading', type='list-item')
          v-list(v-else-if='contenuTableau && contenuTableau.nbPage')
            template(v-for='(page, index) in contenuTableau.nbPage')
              .page-preview(v-if='index === 0')
                .page-image
                  .page-image-bgc(:style="firstPageBgcStyle")
                  img(v-if='contenuTableau.images && contenuTableau.images.length > 0', :src='contenuTableau.images[0]')
                  font-awesome-icon(v-else, :icon="['fad', 'file-alt']", style='font-size:100px; opacity:0.25;')
                span(v-if='!contenuTableau.images || contenuTableau.images.length === 0') Pas de miniatures disponible
                .page-actions
                  v-btn(@click='goToTableau(false)', depressed, color='secondary', small, rounded)
                    span.mr2 Voir tous les tableaux blancs
                    font-awesome-icon(:icon="['far', 'arrow-right']")
              v-list-item(style='padding-left: 0')
                v-list-item-avatar(color='light')
                  font-awesome-icon.fa-lg(:icon="['fad', 'file-alt']")
                v-list-item-content
                  v-list-item-title Page {{index + 1}}
                div.actions__wrapper
                  v-btn(outlined, depressed, color='secondary', x-small, @click='goToTableau(index + 1)')
                    span Aller à cette page
              v-divider(v-if='index > 0 && index < contenuTableau.nbPage - 1')
          v-list(v-else)
            div.empty-content__wrapper
              div Tableau sans contenu
              v-btn.mt3(rounded, color='primary', @click='goToTableau(false)') Aller à cette page
      .large-section
        //- Bloc eleves
        .section-subtitle__header.mt4
          h2.section-subtitle
            div élèves sélectionnés
          v-btn(v-if='selectedSallesUsager && selectedSallesUsager.length' small outlined rounded @click='showSelectEleves = true') Modifier ma sélection
        v-sheet.section-card(id='t-step-3', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          template(v-if='selectedSallesUsager && selectedSallesUsager.length')
            group-list-selection-preview(:groupList="displayedSallesUsagers")
          template(v-else)
            div.empty-content__wrapper
              div Votre tableau n'est attribué à aucun élève. Vous pouvez ajouter les élèves maintenant, ou attribuer ce tableau à des élèves plus tard.
              v-btn.mt3(rounded color='primary' @click='showSelectEleves = true') Ajouter des élèves

</template>
<style lang='sass'>
.page-preview
  background-color: white
  border: solid 1px var(--v-secondary-base)
  width: 100%
  height: 250px
  margin: 0 auto
  border-radius: 10px
  padding: 10px
  position: relative
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  .page-image
    .page-image-bgc
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      opacity: 0.5
      z-index: 1
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    z-index: 5
    img
      object-position: top
      object-fit: cover
      min-width: 100%
  .page-actions
    position: absolute
    bottom: 10px
    right: 16px
    z-index: 20
</style>
